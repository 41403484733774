import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import anonymize from 'img/help/anonymize-data.png';
import mask from 'img/help/img-mask.png';
import recordingElementsImg1 from 'img/help/recording-elements1.png';
import recordingElementsImg2 from 'img/help/recording-elements2.png';
import Code from 'components/help/codeSnippet';

const Content = () => {
  return (
    <div>
      <SubTitle>Text masking</SubTitle>
      <p className="alert alert-primary mb-5 mt-5" role="alert">
        Sensitive data in forms (e.g. passwords and credit card details) is always anonymized in the
        recordings. It’s possible to <strong> anonymize static content</strong> as well, such as
        surnames and other personal information.
      </p>
      <p>
        You can add a <Code inline>data-ls-disabled</Code> attribute to an HTML element. This
        attribute will anonymize the tetxt content, e.g. the surname will be replaced with
        asterisks, like in the example below:
        <Image src={anonymize} alt="Anonymizing data" title="Example anonymizing the content" />
      </p>
      <p>
        The attribute is applied to all child elements, too. In the case of regular clicks and rage
        clicks, clicking on an anonymized element won’t reveal the text value.
      </p>

      <p>
        Anonymize whole text content inside <Code inline>.user-info</Code>:
      </p>
      <Code margin="1rem 0">
        {`
<div data-ls-disabled class="user-info">
<p>Logged as: <span>John Doe</span></p>
</div>
      `}
      </Code>

      <p>Anonymize only sensitive element:</p>
      <Code margin="1rem 0">
        {`
<div class="user-info">
 <p>Logged as: <span data-ls-disabled>John Doe</span></p>
</div>
      `}
      </Code>

      <p>
        You can also use <Code inline>data-ls-enabled</Code> to make content visible. Useful if some
        child elements could be visible.
      </p>
      <Code margin="1rem 0">
        {`
<div data-ls-disabled class="article">
  <p>Lorem ipsum sensitive article.</p>
  <button data-ls-enabled>Subscribe</button>
</div>
      `}
      </Code>

      <SubTitle className="mt-5">Image masking</SubTitle>
      <p>
        It&apos;s possible to mask sensitive images as well. To mask image just add{' '}
        <Code inline>data-ls-mask</Code> attribute to <strong>img</strong> HTML element. During the
        playback, image will be displayed as in the example below:{' '}
      </p>
      <Image src={mask} alt="Anonymizing images" title="Example anonymizing the <img/> tags" />

      <SubTitle className="mt-5">Anonymize through Web UI</SubTitle>
      <p>
        It&apos;s also possible to anonymize data through the LiveSession dashboard, without any
        changes in code. If you are interested, go to website settings and choose the
        &apos;Recording elements&apos; tab. Choose HTML selector and anonymize type. It is worth
        mentioning that selector must be a valid HTML selector.
      </p>
      <Image
        src={recordingElementsImg1}
        alt="Recording elements"
        title="Example recording elements"
      />
      <Image
        src={recordingElementsImg2}
        alt="Recording elements modal"
        title="Example recording elements modal"
      />
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-record-keystrokes/',
    title: "How to record user's keystrokes?",
    description:
      "In this article we'll present how to install our tracking code on WordPress website.",
  },
];

export const frontmatter = {
  metaTitle: 'How to anonymize sensitive page content?',
  metaDescription: 'Learn how to anonymize data',
  canonical: '/help/how-to-anonymize-sensitive-page-content/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Developer" title={frontmatter.metaTitle} {...frontmatter} />
);
